<template>
    <div>
        <div class="main" :style="`background-image: url(${$util.host+bgImg});`">
            <div class="nav">
                <div>
                    <img src="../../assets/images/login/ts.png" alt="" />
                    正在注册【企业用户】
                </div>
            </div>
            <div class="main-content">
                <div class="left-img">
                    <!-- <img src="../../assets/images/login/login-img.png" alt="" /> -->
                </div>
                <div class="login">
                    <el-form style="margin-top: 40px" label-position="left" label-width="100px" :model="from" @submit.native.prevent>
                        <el-form-item label="账户名:">
                            <el-input placeholder="请输入账户名称" v-model="from.accountKey"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号:">
                            <div class="code">
                                <el-dropdown trigger="click" class="drop">
                                    <span class="el-dropdown-link">
                                        +86(中国大陆)<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <!-- <el-dropdown-item>黄金糕黄金糕</el-dropdown-item> -->
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <input placeholder="请输入手机号" v-model="from.accountPhone" type="text" />
                            </div>
                        </el-form-item>
                        <el-form-item label="图形验证码:">
                            <el-input v-model="smaCode"></el-input>
                            <div class="verification">
                                <Code ref="ref_validateCode" @change="changeCode" />
                                <div class="text" @click="changeImg">看不清？<span>换张图</span></div>
                            </div>
                        </el-form-item>
                        <el-form-item label="手机验证码:">
                            <div class="code">
                                <input placeholder="请输入验证码" v-model="from.smsCode" type="text" />
                                <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                                <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">
                                    {{ countDown }}
                                </button>
                            </div>
                        </el-form-item>
                        <el-form-item label="账户密码:">
                            <el-input placeholder="请输入账户密码" type="password" v-model="from.accountPsd"></el-input>
                        </el-form-item>
                        <el-form-item label="密码确认:">
                            <el-input placeholder="请输入账户密码" type="password" v-model="from.accountPsdAgain"></el-input>
                        </el-form-item>
                        <button class="submit" @click="compare">注册</button>
                        <div class="checked">
                            <el-checkbox v-model="checked"></el-checkbox>
                            <div style="margin-left: 10px">
                                已阅读并同意<span @click="goPrivacy">企胖胖平台服务协议、隐私权政策、法律说明</span>
                            </div>
                        </div>
                        <div class="register" @click="goPersonRegister">切换成个人用户注册>>>></div>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Code from "@/components/code.vue";

export default {
    components: {
        Code,
    },
    data() {
        return {
            from: {
                accountKey: "", //账户
                accountPhone: "", //手机号
                accountPsd: "", //密码
                accountPsdAgain: "", //重复输入密码
                accountType: 2, //账号类型 1个人 2企业
                // imgCode: '',//图片验证码
                smsCode: "", //短信验证码
            },
            smaCode: "", //图形码
            countDownShow: false,
            count: 60,
            countDown: "",
            isdisabledFn: false,
            checkCode: "",
            checked: false,
			bgImg:''
        };
    },

    mounted() {
        this.getFrom();
		this.login_logon_bg()
    },

    methods: {
		login_logon_bg() {
			let data = {
				paramKey:"login_logon_bg"
			}
			this.$util.post('/sys-param/list', data).then(res => {
				this.bgImg = res.data[0].paramValue
			})
		},	
        getFrom() {
            //查询是否存在本地储存，自动赋值
            if (sessionStorage.getItem("register") != undefined) {
                this.from = JSON.parse(sessionStorage.getItem("register"));
            }
        },
        goPersonRegister() {
            this.$router.push({ path: "/personRegister" });
        },
        goPrivacy() {
            sessionStorage.setItem("register", JSON.stringify(this.from));
            // id个人0 企业1
            this.$router.push({ path: "/privacy", query: { id: 1 } });
        },
        getCode() {
            // const email = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
            if (this.from.accountPhone == "") {
                return alert("请输入手机号");
            }
            this.$util
                .post("sys-sms/sms-send", {
                    phone: this.from.accountPhone,
                    action: "Login",
                })
                .then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.countDownShow = true;
                        this.settime();
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },

        settime() {
            if (this.count == 0) {
                this.count = 60;
                this.countDownShow = false;
                return false;
            } else {
                this.countDownShow = true;
                this.isdisabledFn = true;
                this.countDown = "重新发送" + "(" + this.count + ")";
                this.count--;
            }
            setTimeout(() => {
                this.settime();
            }, 1000);
        },
        changeCode(value) {
            this.checkCode = value;
        },
        compare() {
			if (this.from.accountKey == "") {
			    return this.$message.error("请输入账户名称");
			}
			if (this.from.accountPhone == "") {
			    return this.$message.error("请输入手机号");
			}
			if (this.from.accountPsd == "") {
			    return this.$message.error("请输入账户密码");
			}
			if (this.from.accountPsdAgain == "") {
			    return this.$message.error("请输入账户密码");
			}
			if (this.from.smsCode == "") {
			    return this.$message.error("请输入验证码");
			}
			
            if (!this.checked) {
                return this.$message.error("请勾选隐私条约进行注册");
            }
            if (this.smaCode == "") {
                return this.$message.error("请输入图形验证码");
            }
            if (this.smaCode.toUpperCase() === this.checkCode) {
                this.$util.post("/user/logon", this.from).then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.$router.push({ path: "/personalLogin" });
                    } else {
                        this.$message.error(res.message);
                    }
                });
            } else {
                this.$message.error("图形验证码错误");
                this.smaCode = "";
                this.$refs["ref_validateCode"].draw();
            }
        },
        changeImg() {
            this.$refs["ref_validateCode"].draw();
        },
    },
};
</script>

<style lang="scss" scoped>
.main {
    // background: url("../../assets/images/login/login-bg.png") round;
    // background-color: #e40012;
	// background-repeat: no-repeat;
	background-size: 100%;
	// background-size:cover;
	// background-position: center;
    // height: 550px;
    padding-bottom: 90px;
    .main-content {
        width: 1200px;
        margin: 0 auto 0;
        padding-top: 54px;
        display: flex;
        align-items: center;

        .left-img {
            width: 405px;
            height: 429px;
            margin-right: 250px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.nav {
    height: 50px;
    background: #e5f4fc;
    color: #2ea7e0;
    display: flex;
    align-items: center;
    div {
        display: flex;
        align-items: center;
        width: 1200px;
        font-size: 16px;
        margin: 0 auto;
        img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
        }
    }
}
.login {
    width: 530px;
    padding: 30px;
    // height: 415px;
    background: #fff;
}
/deep/.el-tabs__item.is-active {
    color: #e40012;
}
/deep/.el-tabs__active-bar {
    background-color: #e40012 !important;
    width: 90px !important;
}
/deep/.el-tabs__item {
    font-size: 22px;
    font-weight: bold;
    color: #666666;
    // margin-right: 40px;
}
/deep/.el-tabs__item:hover {
    color: #e40012;
}
/deep/.el-tabs__nav-wrap::after {
    display: none !important;
}
/deep/.el-form-item__label {
    font-size: 16px;
    color: #999999;
}
/deep/.el-input__inner {
    height: 50px;
    font-size: 16px;
}
.forget {
    font-size: 14px;
    color: #e40012;
    text-align: right;
    cursor: pointer;
    font-weight: bold;
}
.submit {
    margin-top: 40px;
    width: 100%;
    height: 50px;
    background: #e40012;
    font-size: 18px;
    color: #ffff;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}
.register {
    font-size: 14px;
    color: #666666;
    text-align: right;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
}
.code {
    height: 48px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    display: flex;
    padding: 0 15px;
    align-items: center;
    input {
        flex: 1;
        height: 100%;
        border: none;
        outline: none;
        font-size: 16px;
    }
    button {
        background-color: transparent;
        border: none;
        color: #e40012;
        font-weight: bold;
        font-size: 14px;
    }
}
input::-webkit-input-placeholder {
    color: #c0c4cc;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c0c4cc;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c0c4cc;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c0c4cc;
}
.verification {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .text {
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        margin-left: 10px;
        span {
            color: #e40012;
        }
    }
}
.checked {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666666;
    span {
        color: #2ea7e0;
    }
}
.drop {
    cursor: pointer;
    padding-right: 10px;
    border-right: 1px solid #d1d1d1;
    margin-right: 15px;
}
</style>
